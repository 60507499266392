import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './_services/auth.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/main-map/main-map.module').then(m => m.MainMapPageModule)
  },
  {
    path: 'show-spot-on-map/:id',
    loadChildren: () => import('./pages/show-spot-on-map/show-spot-on-map.module').then(m => m.ShowSpotOnMapPageModule)
  }, 
  {
    path: 'search',
    loadChildren: () => import('./pages/search/search.module').then(m => m.SearchPageModule)
  },
  {
    path: 'my-page',
    loadChildren: () => import('./pages/my-page/my-page.module').then(m => m.MyPagePageModule), canActivate: [AuthGuard]
  },
  {
    path: 'show-spot/:id',
    loadChildren: () => import('./pages/show-spot/show-spot.module').then(m => m.ShowSpotPageModule)
  },
  {
    path: 'my-map',
    loadChildren: () => import('./pages/my-map/my-map.module').then(m => m.MyMapPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'my-profile',
    loadChildren: () => import('./pages/my-profile/my-profile.module').then(m => m.MyProfilePageModule), canActivate: [AuthGuard]
  },
  {
    path: 'main-map',
    loadChildren: () => import('./pages/main-map/main-map.module').then(m => m.MainMapPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'admin/upload-images/:id',
    loadChildren: () => import('./pages/admin/upload-images/upload-images.module').then(m => m.UploadImagesPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'admin/edit-spots',
    loadChildren: () => import('./pages/admin/edit-spots/edit-spots.module').then(m => m.EditSpotsPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'admin/upload-panorama/:id',
    loadChildren: () => import('./pages/admin/upload-panorama/upload-panorama.module').then(m => m.UploadPanoramaPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'pages',
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesPageModule)
  },
  {
    path: 'admin/new-spot',
    loadChildren: () => import('./pages/admin/new-spot/new-spot.module').then(m => m.NewSpotPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'admin/edit-spot/:id',
    loadChildren: () => import('./pages/admin/edit-spot/edit-spot.module').then(m => m.EditSpotPageModule), canActivate: [AuthGuard]
  }
  ,
  {
    path: 'admin/edit-users',
    loadChildren: () => import('./pages/admin/edit-users/edit-users.module').then(m => m.EditUsersPageModule), canActivate: [AuthGuard]
  }
  ,
  {
    path: 'admin/edit-user/:id',
    loadChildren: () => import('./pages/admin/edit-user/edit-user.module').then(m => m.EditUserPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'admin/new-user',
    loadChildren: () => import('./pages/admin/new-user/new-user.module').then(m => m.NewUserPageModule), canActivate: [AuthGuard]
  }
  ,
  {
    path: 'my-spots',
    loadChildren: () => import('./pages/my-spots/my-spots.module').then(m => m.MySpotsPageModule), canActivate: [AuthGuard]
  }
  ,
  {
    path: 'my-favorites',
    loadChildren: () => import('./pages/my-favorites/my-favorites.module').then(m => m.MyFavoritesPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'my-new-spot',
    loadChildren: () => import('./pages/my-new-spot/my-new-spot.module').then(m => m.MyNewSpotPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'admin/embed/:id',
    loadChildren: () => import('./pages/admin/embed/embed.module').then(m => m.EmbedPageModule), canActivate: [AuthGuard]
  },
  {
    path: 'icon-description',
    loadChildren: () => import('./pages/icon-description/icon-description.module').then(m => m.IconDescriptionPageModule), canActivate: [AuthGuard]
  }
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
