// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  title: 'DykkerSpots 2.0',
  apiURL: 'https://api3.dykkerspots.dk/api/',
  //apiURL: 'https://localhost:7238/api/',
  apiKey: 'p84wj5ft9j45t7j',
  imageUrl: 'https://api3.dykkerspots.dk/SpotImages/',
  imageHqUrl: 'https://api3.dykkerspots.dk/SpotImages/HQ/',
  imageThumpUrl: 'https://api3.dykkerspots.dk/SpotImages/Thump/',
  imageBigUrl: 'https://api3.dykkerspots.dk/SpotImages/',
  view360Url: 'https://api3.dykkerspots.dk/360/',
  localGfxUrl:'./assets/gfx/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
