import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

//import { AccountService } from '@app/_services';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        //private accountService: AccountService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        //const user = this.accountService.userValue;
        var user = null;
        if (localStorage.getItem("user") != null && localStorage.getItem("user") != "")
        {            
            user = JSON.parse(localStorage.getItem("user"));
        }
    
       // console.log(user);
        if (user != null) {
            // authorised so return true
            if (user.userId > 0) {
                return true;
            }
            else {
                // not logged in so redirect to login page with the return url
                this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
                return false;
            }
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}